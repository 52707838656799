import { IColor, IFNTheme, themeGenerator } from '@food/css-manager';
import logoFood from '../static/logoFOOD.svg';
import logoFoodService from '../static/logoFOODSERVICE.svg';
import logoDolcesalato from '../static/logoDOLCESALATO.svg';
import logoItalianFoodNet from '../static/logoIFN.svg';
import logoPersonalCare360 from '../static/logoPERSONALCARE360.svg';
import logoHomeCare360 from '../static/logoHOMECARE360.svg';

import { defaultColor } from '@food/css-manager';
import {
	Abbonamento__C_Rivista__C_Enum,
	Product2_Brand__C_EnumFiltered,
	TargetSite,
	UserRole,
} from '../server-types';

const devAPIUrl = 'https://api2.italianfood.net';
const prodAPIUrl = 'https://api.italianfood.net';

const devStripeApi = 'pk_test_mDuSVEDshhPzDuFbz5p3U6fK';
const prodStripeApi = 'pk_live_tNstcLBztJ9ixRq4CIfHHy3A';

const ECOMMERCE_IBAN = 'IT49N0760112700000011279437';

export type UserDashboardTheme = IFNTheme & {
	colors: {
		ecommerce_blue: IColor;
	};
};

const baseTheme = themeGenerator({
	alt2Hue: 41,
	primaryHue: 358,
	secondaryHue: 149,
	altHue: 253,
	greyHue: 0,
	disabledSaturation: 0.3,
	ink: '#212121',
	white: '#ffffff',
	inkWhite: '#ededed',
	light: 0.95,
	plain: 0.6,
	dark: 0.3,
	dimmerOpacity: 0.5,
	baseTiming: 0.28,
	directShadowX: 0.2,
	directShadowY: 0.4,
	directShadowDiffusion: 0.4,
	ambientShadowDiffusion: 1.1,
	directShadowColor: 'rgba(0,0,0,.15)',
	ambientShadowColor: 'rgba(0,0,0,.15)',
});

baseTheme.zIndexes.P1_SlightlyAbove = 8;
baseTheme.font.baseSize = 16; // CHECK

baseTheme.button = {
	fontWeight: baseTheme.font.weights.semibold,
};
baseTheme.colors.alt = defaultColor(
	209,
	{ plain: 0.75, light: 0.95, dark: 0.3 },
	{ plain: 0.35, disabled: 0.3 },
);
baseTheme.colors.grey = defaultColor(
	0,
	{ plain: 0.6, light: 0.8, dark: 0.3 },
	{ plain: 0, disabled: 0 },
);
baseTheme.zIndexes.P1_SlightlyAbove = 8;

const deployTheme: UserDashboardTheme = {
	...baseTheme,
	colors: {
		...baseTheme.colors,
		ecommerce_blue: {
			plain: '#596f96',
			light: '#6f82a3',
			dark: '#32466b',
			disabled: {
				plain: '#000000', // non usato
				light: '#cdd5e2',
				dark: '#000000', // non usato
			},
		},
	},
};

const globalVerificationUrl = window.location.pathname + '#login';

export const configs = {
		'stage-userdashboard.gruppofood.com': {
			TARGET_SITE: 'FOODWEB',
			BASE_URL: devAPIUrl,
			GRAPHQL_API: devAPIUrl + '/graphql',
			API_ENDPOINT: {
				AUTH: devAPIUrl + '/api/authenticate',
				VERIFY: devAPIUrl + '/api/verify',
				TRACKING: devAPIUrl + '/api/bucket/tracking',
				FORGOT_PASSWORD: devAPIUrl + '/api/forgot-password',
				RESET_PASSWORD: devAPIUrl + '/api/confirm-password'
			},
			VERIFICATION_URL: globalVerificationUrl,
			SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
			BRAND_SF: 'FOOD',
			OTHER_BRAND_SF: [],
			theme: deployTheme,
			//FLUENT_LOAD_PATH:
			//	'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
			SUBSCRIBE_PATH: '/abbonati-a-food/',
			AWARD_FILTER_NAME: 'Prodotto FOOD',
			AWARD_VOTE_URL: 'https://vote.prodottofood.it',
			LANG: 'it',
			SUPPORT_EMAIL: 'support@gruppofood.com',
			PARDOT_NEWSLETTER_FORM: 'https://pardot.gruppofood.com/l/656763/2020-02-04/p1cgk',
			SF_ITEM: 'FOOD_DIGITALE_PERIODICO_COMPLETO',
			HIDE_HOME_LAST_ITEM: false,
			LOGO: logoFood,
			STRIPE_PUBLIC_KEY: devStripeApi,
			ECOMMERCE_IBAN,
			EDICOLA_HOME_URL: '/edicola/',
			ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
			ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Food,
			KNOWLEDGECENTER_VERTICALS: [
				{
					id: 'NA35BpN2_7U~fdf',
					name: 'Salumi', // serve per matchare le category dei magazines; deve essere uguale a HUB!
					news_link: 'https://www.foodweb.it/category/mercati/salumi/',
					newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh5m',
					tabs: [
						{
							title: 'I Mercati',
							slug: 'mercati',
							type: 'collection',
							name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
							id: '53AfVrMcCBk~b57'
						},
						{
							title: 'Data',
							slug: 'data',
							type: 'wordpressData',
							url: 'https://www.foodweb.it/knowledge-center-data-salumi/'
						},
						{
							title: 'Annual Reports',
							slug: 'annual-reports',
							type: 'collection',
							name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
							id: 'JT6qGoXw3Lw~b57'
						},
						{
							title: 'Lanci di prodotto',
							type: 'extlink',
							target: '_blank',
							url: 'https://www.foodweb.it/launch/?product_category=salumi&order='
						},
						{
							title: 'Newsletter',
							slug: 'newsletter',
							type: 'newsletter'
						}
					]
				}
			],
			KNOWLEDGECENTER_MAGAZINECOLLECTIONS: [
				{
					name: 'rivista', // serve per matchare le category dei magazines; deve essere uguale a HUB!
					id: 'nfRj91B7qmM~cf4',
					color: '#89a6c0',
					label: 'Mensile',
					in_dashboard: true
				},
				{
					name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
					id: '53AfVrMcCBk~b57',
					in_dashboard: false
				},
				{
					name: 'annual reports', // serve per matchare le category dei magazines; deve essere uguale a HUB!
					id: 'JT6qGoXw3Lw~b57',
					color: '#ec6f68',
					in_dashboard: true
				}			
			],
			KNOWLEDGECENTER_SECTIONS: [
			]
		},
	'dev.foodweb.it': {
		TARGET_SITE: 'FOODWEB',
		BASE_URL: devAPIUrl,
		GRAPHQL_API: devAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: devAPIUrl + '/api/authenticate',
			VERIFY: devAPIUrl + '/api/verify',
			TRACKING: devAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: devAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: devAPIUrl + '/api/confirm-password'
		},
		VERIFICATION_URL: globalVerificationUrl,
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'FOOD',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		SUBSCRIBE_PATH: '/abbonati-a-food/',
		AWARD_FILTER_NAME: 'Prodotto FOOD',
		AWARD_VOTE_URL: 'https://vote.prodottofood.it',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.gruppofood.com/l/656763/2020-02-04/p1cgk',
		SF_ITEM: 'FOOD_DIGITALE_PERIODICO_COMPLETO',
		HIDE_HOME_LAST_ITEM: false,
		LOGO: logoFood,
		STRIPE_PUBLIC_KEY: devStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/edicola/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Food,
		KNOWLEDGECENTER_VERTICALS: [
			{
				id: 'NA35BpN2_7U~fdf',
				name: 'Salumi', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/salumi/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh5m',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-salumi/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: 'JT6qGoXw3Lw~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=salumi&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			}
		],
		KNOWLEDGECENTER_MAGAZINECOLLECTIONS: [
			{
				name: 'rivista', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'nfRj91B7qmM~cf4',
				color: '#89a6c0',
				label: 'Mensile',
				in_dashboard: true
			},
			{
				name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: '53AfVrMcCBk~b57',
				in_dashboard: false
			},
			{
				name: 'annual reports', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'JT6qGoXw3Lw~b57',
				color: '#ec6f68',
				in_dashboard: true
			}			
		],
		KNOWLEDGECENTER_SECTIONS: [
		]
	},
	'stage.foodweb.it': {
		TARGET_SITE: 'FOODWEB',
		BASE_URL: devAPIUrl,
		GRAPHQL_API: devAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: devAPIUrl + '/api/authenticate',
			VERIFY: devAPIUrl + '/api/verify',
			TRACKING: devAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: devAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: devAPIUrl + '/api/confirm-password'
		},
		VERIFICATION_URL: globalVerificationUrl,
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'FOOD',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		SUBSCRIBE_PATH: '/abbonati-a-food/',
		AWARD_FILTER_NAME: 'Prodotto FOOD',
		AWARD_VOTE_URL: 'https://vote.prodottofood.it',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.gruppofood.com/l/656763/2020-02-04/p1cgk',
		SF_ITEM: 'FOOD_DIGITALE_PERIODICO_COMPLETO',
		HIDE_HOME_LAST_ITEM: false,
		LOGO: logoFood,
		STRIPE_PUBLIC_KEY: devStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/edicola/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Food,
		KNOWLEDGECENTER_VERTICALS: [
			{
				id: 'NA35BpN2_7U~fdf',
				name: 'Salumi', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/salumi/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh5m',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-salumi/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: 'JT6qGoXw3Lw~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=salumi&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			}
		],
		KNOWLEDGECENTER_MAGAZINECOLLECTIONS: [
			{
				name: 'rivista', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'nfRj91B7qmM~cf4',
				color: '#89a6c0',
				label: 'Mensile',
				in_dashboard: true
			},
			{
				name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: '53AfVrMcCBk~b57',
				in_dashboard: false
			},
			{
				name: 'annual reports', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'JT6qGoXw3Lw~b57',
				color: '#ec6f68',
				in_dashboard: true
			}			
		],
		KNOWLEDGECENTER_SECTIONS: [
		]
	},
	'stage-dev.foodweb.it': {
		TARGET_SITE: 'FOODWEB',
		BASE_URL: devAPIUrl,
		GRAPHQL_API: devAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: devAPIUrl + '/api/authenticate',
			VERIFY: devAPIUrl + '/api/verify',
			TRACKING: devAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: devAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: devAPIUrl + '/api/confirm-password'
		},
		VERIFICATION_URL: globalVerificationUrl,
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'FOOD',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		SUBSCRIBE_PATH: '/abbonati-a-food/',
		AWARD_FILTER_NAME: 'Prodotto FOOD',
		AWARD_VOTE_URL: 'https://vote.prodottofood.it',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.gruppofood.com/l/656763/2020-02-04/p1cgk',
		SF_ITEM: 'FOOD_DIGITALE_PERIODICO_COMPLETO',
		HIDE_HOME_LAST_ITEM: false,
		LOGO: logoFood,
		STRIPE_PUBLIC_KEY: devStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/edicola/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Food,
		KNOWLEDGECENTER_VERTICALS: [
			{
				id: 'NA35BpN2_7U~fdf',
				name: 'Salumi', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/salumi/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-12/4vlpg5',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-salumi/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: 'JT6qGoXw3Lw~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=salumi&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			}
		],
		KNOWLEDGECENTER_MAGAZINECOLLECTIONS: [
			{
				name: 'rivista', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'nfRj91B7qmM~cf4',
				color: '#89a6c0',
				label: 'Mensile',
				in_dashboard: true
			},
			{
				name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: '53AfVrMcCBk~b57',
				in_dashboard: false
			},
			{
				name: 'annual reports', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'JT6qGoXw3Lw~b57',
				color: '#ec6f68',
				in_dashboard: true
			}			
		],
		KNOWLEDGECENTER_SECTIONS: [
		]
	},
	'stage.dolcesalato.com': {
		TARGET_SITE: 'DOLCESALATO',
		BASE_URL: devAPIUrl,
		GRAPHQL_API: devAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: devAPIUrl + '/api/authenticate',
			VERIFY: devAPIUrl + '/api/verify',
			TRACKING: devAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: devAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: devAPIUrl + '/api/confirm-password'
		},
		VERIFICATION_URL: globalVerificationUrl,
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'DOLCESALATO',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		SUBSCRIBE_PATH: '/abbonamenti/',
		AWARD_FILTER_NAME: 'Prodotto DOLCESALATO',
		AWARD_VOTE_URL: 'https://awards.dolcesalato.com',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.gruppofood.com/l/656763/2020-02-04/p1cgk',
		SF_ITEM: 'DOLCESALATO_DIGITALE_PERIODICO_COMPLETO',
		HIDE_HOME_LAST_ITEM: false,
		LOGO: logoDolcesalato,
		RIVISTA: Abbonamento__C_Rivista__C_Enum.Dolcesalato,
		STRIPE_PUBLIC_KEY: devStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/edicola/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Dolcesalato,
	},
	'dev.dolcesalato.com': {
		TARGET_SITE: 'DOLCESALATO',
		BASE_URL: prodAPIUrl,
		GRAPHQL_API: prodAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: prodAPIUrl + '/api/authenticate',
			VERIFY: prodAPIUrl + '/api/verify',
			TRACKING: prodAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: prodAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: prodAPIUrl + '/api/confirm-password'
		},
		VERIFICATION_URL: globalVerificationUrl,
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'DOLCESALATO',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		SUBSCRIBE_PATH: '/abbonamenti/',
		AWARD_FILTER_NAME: 'Prodotto DOLCESALATO',
		AWARD_VOTE_URL: 'https://awards.dolcesalato.com',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.gruppofood.com/l/656763/2020-02-04/p1cgk',
		SF_ITEM: 'DOLCESALATO_DIGITALE_PERIODICO_COMPLETO',
		HIDE_HOME_LAST_ITEM: false,
		LOGO: logoDolcesalato,
		RIVISTA: Abbonamento__C_Rivista__C_Enum.Dolcesalato,
		STRIPE_PUBLIC_KEY: devStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/edicola/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Dolcesalato,
	},
	'www.dolcesalato.com': {
		TARGET_SITE: 'DOLCESALATO',
		BASE_URL: prodAPIUrl,
		GRAPHQL_API: prodAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: prodAPIUrl + '/api/authenticate',
			VERIFY: prodAPIUrl + '/api/verify',
			TRACKING: prodAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: prodAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: prodAPIUrl + '/api/confirm-password'
		},
		VERIFICATION_URL: globalVerificationUrl,
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'DOLCESALATO',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		AWARD_FILTER_NAME: 'Prodotto DOLCESALATO',
		AWARD_VOTE_URL: 'https://awards.dolcesalato.com',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.dolcesalato.com/l/656763/2020-02-04/p1chf',
		SF_ITEM: 'DOLCESALATO_DIGITALE_PERIODICO_COMPLETO',
		HIDE_HOME_LAST_ITEM: false,
		LOGO: logoDolcesalato,
		RIVISTA: Abbonamento__C_Rivista__C_Enum.Dolcesalato,
		STRIPE_PUBLIC_KEY: prodStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/edicola/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Dolcesalato,
		SUBSCRIBE_PATH: '/abbonati-a-dolcesalato/'
	},
	'localhost': {
		TARGET_SITE: TargetSite.Foodweb,
		BASE_URL: prodAPIUrl,
		VERIFICATION_URL: globalVerificationUrl,
		GRAPHQL_API: prodAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: prodAPIUrl + '/api/authenticate',
			VERIFY: prodAPIUrl + '/api/verify',
			TRACKING: prodAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: prodAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: prodAPIUrl + '/api/confirm-password'
		},
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'FOOD',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		//FLUENT_LOAD_PATH: 'locales/{{lng}}/{{ns}}.ftl',
		FLUENT_LOAD_PATH: '/food/wp-foodwebit/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.gruppofood.com/l/656763/2020-02-04/p1cgk',
		HIDE_HOME_LAST_ITEM: false,
		LOGO: logoFood,
		SELECTED_PILIST: 'DOLCESALATO',
		AWARD_FILTER_NAME: 'Prodotto FOOD',
		AWARD_VOTE_URL: 'https://vote.prodottofood.it',
		STRIPE_PUBLIC_KEY: devStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Food,
		KNOWLEDGECENTER_VERTICALS: [
			{
				id: 'rZNGT8f29Uw~bbe',
				name: 'Salumi', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/salumi/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh5m',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-salumi/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=salumi&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'qH5cqsIPTO0~fc6',
				name: 'Carni', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/carni/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-carni/'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=carni&order='
					}
				]
			},
			{
				id: 'S9h8mhaGXbw~98b',
				name: 'Dairy', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/dairy/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh6x',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-dairy/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=dairy&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: '7BnCXXpA8E8~fbb',
				name: 'Pasta', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/pasta/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvx77',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-pasta/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=pasta&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'GUKzwgCmEK0~f47',
				name: 'Bakery', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/bakery/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvx25',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-bakery/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=bakery&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'cQsQBfOYqSw~ada',
				name: 'Conserve', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/conserve/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-conserve/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=conserve&order='
					}
				]
			},
			{
				id: 'PlwcDJy0Iyo~9b1',
				name: 'Condimenti', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/condimenti-mercati/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-condimenti/'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=condimenti-mercati&order='
					}
				]
			},
			{
				id: '.SEvp.SjiGE~e67',
				name: 'Olio', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/olio/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dpvg',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-olio/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=olio&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'dYUxdaImPIc~b46',
				name: 'Beverage', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/beverage/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-beverage/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=beverage&order='
					}
				]
			},
			{
				id: '8rPCaW78pAU~eed',
				name: 'Ortofrutta', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/ortofrutta/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-ortofrutta/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=ortofrutta&order='
					}
				]
			},
			{
				id: 's1wJbtxcKa0~d0a',
				name: 'Frozen', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/frozen/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-frozen/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=frozen&order='
					}
				]
			},
			{
				id: 'rMD0VOHhqM4~e7e',
				name: 'Fresco', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/fresco/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=fresco&order='
					}
				]
			},
			{
				id: 'hXygh.UD_yQ~de2',
				name: 'Grocery', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/grocery/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-grocery/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=grocery&order='
					}
				]
			},
			{
				id: 'vQUlCbGPQxc~9d6',
				name: 'retail', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2022-01-14/8q699y',
				news_link: 'https://www.foodweb.it/category/retail/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-retail/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Mappe e Whitepaper',
						slug: 'mappe-e-whitepaper',
						id: 'wYHrBsW297k~bd6',
						type: 'collection',
						name: 'Retail', // serve per matchare le category dei magazines; deve essere uguale a HUB!
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			}
		],
		KNOWLEDGECENTER_MAGAZINECOLLECTIONS: [
			{
				name: 'rivista', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'nfRj91B7qmM~cf4',
				color: '#89a6c0',
				label: 'Mensile',
				in_dashboard: true
			},
			{
				name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: '4aSIksnqH08~f0d',
				in_dashboard: false
			},
			{
				name: 'annual reports', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: '53AfVrMcCBk~b57',
				color: '#ec6f68',
				in_dashboard: true
			}
		],
		KNOWLEDGECENTER_SECTIONS: [
		]
	},
	'www.foodweb.it': {
		TARGET_SITE: 'FOODWEB',
		BASE_URL: prodAPIUrl,
		GRAPHQL_API: prodAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: prodAPIUrl + '/api/authenticate',
			VERIFY: prodAPIUrl + '/api/verify',
			TRACKING: prodAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: prodAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: prodAPIUrl + '/api/confirm-password'
		},
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'FOOD',
		VERIFICATION_URL: globalVerificationUrl,
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		SUBSCRIBE_PATH: '/abbonati-a-food/',
		AWARD_FILTER_NAME: 'Prodotto FOOD',
		AWARD_VOTE_URL: 'https://vote.prodottofood.it',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.gruppofood.com/l/656763/2020-02-04/p1cgk',
		SF_ITEM: 'FOOD_DIGITALE_PERIODICO_COMPLETO',
		HIDE_HOME_LAST_ITEM: false,
		LOGO: logoFood,
		RIVISTA: Abbonamento__C_Rivista__C_Enum.Food,
		STRIPE_PUBLIC_KEY: prodStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Food,
		KNOWLEDGECENTER_VERTICALS: [
			{
				id: 'rZNGT8f29Uw~bbe',
				name: 'Salumi', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/salumi/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh5m',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-salumi/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=salumi&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'qH5cqsIPTO0~fc6',
				name: 'Carni', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/carni/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-carni/'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=carni&order='
					}
				]
			},
			{
				id: 'S9h8mhaGXbw~98b',
				name: 'Dairy', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/dairy/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh6x',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-dairy/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=dairy&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: '7BnCXXpA8E8~fbb',
				name: 'Pasta', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/pasta/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvx77',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-pasta/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=pasta&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'GUKzwgCmEK0~f47',
				name: 'Bakery', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/bakery/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvx25',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-bakery/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=bakery&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'cQsQBfOYqSw~ada',
				name: 'Conserve', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/conserve/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-conserve/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=conserve&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'PlwcDJy0Iyo~9b1',
				name: 'Condimenti', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/condimenti-mercati/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-condimenti/'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=condimenti-mercati&order='
					}
				]
			},
			{
				id: '.SEvp.SjiGE~e67',
				name: 'Olio', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/olio/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dpvg',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-olio/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=olio&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'dYUxdaImPIc~b46',
				name: 'Beverage', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/beverage/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-beverage/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=beverage&order='
					}
				]
			},
			{
				id: '8rPCaW78pAU~eed',
				name: 'Ortofrutta', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/ortofrutta/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-ortofrutta/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=ortofrutta&order='
					}
				]
			},
			{
				id: 's1wJbtxcKa0~d0a',
				name: 'Frozen', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/frozen/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-frozen/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=frozen&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'rMD0VOHhqM4~e7e',
				name: 'Fresco', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/fresco/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=fresco&order='
					}
				]
			},
			{
				id: 'hXygh.UD_yQ~de2',
				name: 'Grocery', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/grocery/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-grocery/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=grocery&order='
					}
				]
			},
			{
				id: 'vQUlCbGPQxc~9d6',
				name: 'retail', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2022-01-14/8q699y',
				news_link: 'https://www.foodweb.it/category/retail/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-retail/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Mappe e Whitepaper',
						slug: 'mappe-e-whitepaper',
						id: 'wYHrBsW297k~bd6',
						type: 'collection',
						name: 'Retail', // serve per matchare le category dei magazines; deve essere uguale a HUB!
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			}
		],
		KNOWLEDGECENTER_MAGAZINECOLLECTIONS: [
			{
				name: 'rivista', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'nfRj91B7qmM~cf4',
				color: '#89a6c0',
				label: 'Mensile',
				in_dashboard: true
			},
			{
				name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: '4aSIksnqH08~f0d',
				in_dashboard: false
			},
			{
				name: 'annual reports', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: '53AfVrMcCBk~b57',
				color: '#ec6f68',
				in_dashboard: true
			}			
		],
		KNOWLEDGECENTER_SECTIONS: [
		]
	},
	'www.foodserviceweb.it': {
		TARGET_SITE: 'FOODSERVICEWEB',
		BASE_URL: prodAPIUrl,
		VERIFICATION_URL: globalVerificationUrl,
		GRAPHQL_API: prodAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: prodAPIUrl + '/api/authenticate',
			VERIFY: prodAPIUrl + '/api/verify',
			TRACKING: prodAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: prodAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: prodAPIUrl + '/api/confirm-password'
		},
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'FOOD_SERVICE',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM:
			'https://pardot.foodserviceweb.it/l/656763/2020-01-31/n71bh',
		SF_ITEM: 'FOOD_SERVICE_DIGITALE_PERIODICO_COMPLETO',
		HIDE_HOME_LAST_ITEM: true,
		LOGO: logoFoodService,
		RIVISTA: Abbonamento__C_Rivista__C_Enum.FoodService,
		STRIPE_PUBLIC_KEY: prodStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Foodservice,
		SUBSCRIBE_PATH: '/abbonati-a-foodservice/'
	},
	'stage.foodserviceweb.it': {
		TARGET_SITE: 'FOODSERVICEWEB',
		BASE_URL: devAPIUrl,
		VERIFICATION_URL: globalVerificationUrl,
		GRAPHQL_API: devAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: devAPIUrl + '/api/authenticate',
			VERIFY: devAPIUrl + '/api/verify',
			TRACKING: devAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: devAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: devAPIUrl + '/api/confirm-password'
		},
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'FOOD_SERVICE',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM:
			'https://pardot.foodserviceweb.it/l/656763/2020-01-31/n71bh',
		SF_ITEM: 'FOOD_SERVICE_DIGITALE_PERIODICO_COMPLETO',
		HIDE_HOME_LAST_ITEM: true,
		LOGO: logoFoodService,
		RIVISTA: Abbonamento__C_Rivista__C_Enum.FoodService,
		STRIPE_PUBLIC_KEY: prodStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Foodservice,
		SUBSCRIBE_PATH: '/abbonati-a-foodservice/'
	},
	'news.italianfood.net': {
		TARGET_SITE: 'IFN',
		BASE_URL: prodAPIUrl,
		VERIFICATION_URL: globalVerificationUrl,
		GRAPHQL_API: prodAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: prodAPIUrl + '/api/authenticate',
			VERIFY: prodAPIUrl + '/api/verify',
			TRACKING: prodAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: prodAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: prodAPIUrl + '/api/confirm-password'
		},
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'ITALIANFOOD_NET',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		LANG: 'en',
		SUPPORT_EMAIL: 'support@italianfood.net',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.italianfood.net/l/656763/2020-02-04/p1cgt',
		LOGO: logoItalianFoodNet,
		STRIPE_PUBLIC_KEY: prodStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.ItalianfoodNet,
		KNOWLEDGECENTER_VERTICALS: [
			{
				id: 'rZNGT8f29Uw~bbe',
				name: 'Salumi', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/salumi/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh5m',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-salumi/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=salumi&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'qH5cqsIPTO0~fc6',
				name: 'Carni', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/carni/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-carni/'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=carni&order='
					}
				]
			},
			{
				id: 'S9h8mhaGXbw~98b',
				name: 'Dairy', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/dairy/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh6x',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-dairy/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=dairy&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: '7BnCXXpA8E8~fbb',
				name: 'Pasta', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/pasta/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvx77',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-pasta/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=pasta&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'GUKzwgCmEK0~f47',
				name: 'Bakery', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/bakery/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvx25',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-bakery/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=bakery&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'cQsQBfOYqSw~ada',
				name: 'Conserve', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/conserve/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-conserve/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=conserve&order='
					}
				]
			},
			{
				id: 'PlwcDJy0Iyo~9b1',
				name: 'Condimenti', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/condimenti-mercati/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-condimenti/'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=condimenti-mercati&order='
					}
				]
			},
			{
				id: '.SEvp.SjiGE~e67',
				name: 'Olio', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/olio/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvydp',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-olio/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=olio&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'dYUxdaImPIc~b46',
				name: 'Beverage', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/beverage/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-beverage/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=beverage&order='
					}
				]
			},
			{
				id: '8rPCaW78pAU~eed',
				name: 'Ortofrutta', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/ortofrutta/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-ortofrutta/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=ortofrutta&order='
					}
				]
			},
			{
				id: 's1wJbtxcKa0~d0a',
				name: 'Frozen', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/frozen/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-frozen/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=frozen&order='
					}
				]
			},
			{
				id: 'rMD0VOHhqM4~e7e',
				name: 'Fresco', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/fresco/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=fresco&order='
					}
				]
			},
			{
				id: 'hXygh.UD_yQ~de2',
				name: 'Grocery', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/grocery/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-grocery/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=grocery&order='
					}
				]
			},
			{
				id: 'vQUlCbGPQxc~9d6',
				name: 'retail', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2022-01-14/8q699y',
				news_link: 'https://www.foodweb.it/category/retail/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-retail/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			}
		],
		KNOWLEDGECENTER_MAGAZINECOLLECTIONS: [
			{
				name: 'magazine', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'tw1vwVBUlmg~8e4',
				color: '#89a6c0',
				label: 'Magazine',
				in_dashboard: true
			},
			{
				name: 'special issues', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: '5KqEuNxtXW8~f2b',
				in_dashboard: false
			},
			{
				name: 'reports', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'nKMgzxwGO0g~b86',
				color: '#ec6f68',
				in_dashboard: true
			}			
		],
		KNOWLEDGECENTER_SECTIONS: [
		]
	},

	"stage-news.italianfood.net":{
		TARGET_SITE: 'IFN',
		BASE_URL: prodAPIUrl,
		VERIFICATION_URL: globalVerificationUrl,
		GRAPHQL_API: prodAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: prodAPIUrl + '/api/authenticate',
			VERIFY: prodAPIUrl + '/api/verify',
			TRACKING: prodAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: prodAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: prodAPIUrl + '/api/confirm-password'
		},
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'ITALIANFOOD_NET',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		LANG: 'en',
		SUPPORT_EMAIL: 'support@italianfood.net',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.italianfood.net/l/656763/2020-02-04/p1cgt',
		LOGO: logoItalianFoodNet,
		STRIPE_PUBLIC_KEY: prodStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.ItalianfoodNet,
		KNOWLEDGECENTER_VERTICALS: [
			{
				id: 'rZNGT8f29Uw~bbe',
				name: 'Salumi', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/salumi/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-12/4vlpg5',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-salumi/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=salumi&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'qH5cqsIPTO0~fc6',
				name: 'Carni', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/carni/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-carni/'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=carni&order='
					}
				]
			},
			{
				id: 'S9h8mhaGXbw~98b',
				name: 'Dairy', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/dairy/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvxbp',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-dairy/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=dairy&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: '7BnCXXpA8E8~fbb',
				name: 'Pasta', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/pasta/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvx77',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-pasta/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=pasta&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'GUKzwgCmEK0~f47',
				name: 'Bakery', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/bakery/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvx25',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-bakery/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=bakery&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'cQsQBfOYqSw~ada',
				name: 'Conserve', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/conserve/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-conserve/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=conserve&order='
					}
				]
			},
			{
				id: 'PlwcDJy0Iyo~9b1',
				name: 'Condimenti', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/condimenti-mercati/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-condimenti/'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=condimenti-mercati&order='
					}
				]
			},
			{
				id: '.SEvp.SjiGE~e67',
				name: 'Olio', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/olio/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvydp',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-olio/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=olio&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'dYUxdaImPIc~b46',
				name: 'Beverage', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/beverage/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-beverage/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=beverage&order='
					}
				]
			},
			{
				id: '8rPCaW78pAU~eed',
				name: 'Ortofrutta', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/ortofrutta/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-ortofrutta/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=ortofrutta&order='
					}
				]
			},
			{
				id: 's1wJbtxcKa0~d0a',
				name: 'Frozen', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/frozen/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-frozen/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=frozen&order='
					}
				]
			},
			{
				id: 'rMD0VOHhqM4~e7e',
				name: 'Fresco', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/fresco/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=fresco&order='
					}
				]
			},
			{
				id: 'hXygh.UD_yQ~de2',
				name: 'Grocery', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/grocery/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-grocery/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=grocery&order='
					}
				]
			},
			{
				id: 'vQUlCbGPQxc~9d6',
				name: 'retail', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2022-01-14/8q699y',
				news_link: 'https://www.foodweb.it/category/retail/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-retail/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			}
		],
		KNOWLEDGECENTER_MAGAZINECOLLECTIONS: [
			{
				name: 'magazine', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'tw1vwVBUlmg~8e4',
				color: '#89a6c0',
				label: 'Magazine',
				in_dashboard: true
			},
			{
				name: 'special issues', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: '5KqEuNxtXW8~f2b',
				in_dashboard: false
			},
			{
				name: 'reports', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'nKMgzxwGO0g~b86',
				color: '#ec6f68',
				in_dashboard: true
			}			
		],
		KNOWLEDGECENTER_SECTIONS: [
		]
	},




	// ------------------- TEMPORARY ENTRIES FOR PRODUCTION MIGRATION -------------------




	'prod.foodweb.it': {
		TARGET_SITE: 'FOODWEB',
		BASE_URL: prodAPIUrl,
		GRAPHQL_API: prodAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: prodAPIUrl + '/api/authenticate',
			VERIFY: prodAPIUrl + '/api/verify',
			TRACKING: prodAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: prodAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: prodAPIUrl + '/api/confirm-password'
		},
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'FOOD',
		VERIFICATION_URL: globalVerificationUrl,
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		SUBSCRIBE_PATH: '/abbonati-a-food/',
		AWARD_FILTER_NAME: 'Prodotto FOOD',
		AWARD_VOTE_URL: 'https://vote.prodottofood.it',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.gruppofood.com/l/656763/2020-02-04/p1cgk',
		SF_ITEM: 'FOOD_DIGITALE_PERIODICO_COMPLETO',
		HIDE_HOME_LAST_ITEM: false,
		LOGO: logoFood,
		RIVISTA: Abbonamento__C_Rivista__C_Enum.Food,
		STRIPE_PUBLIC_KEY: prodStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Food,
		KNOWLEDGECENTER_VERTICALS: [
			{
				id: 'rZNGT8f29Uw~bbe',
				name: 'Salumi', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/salumi/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh5m',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-salumi/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=salumi&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'qH5cqsIPTO0~fc6',
				name: 'Carni', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/carni/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-carni/'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=carni&order='
					}
				]
			},
			{
				id: 'S9h8mhaGXbw~98b',
				name: 'Dairy', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/dairy/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh6x',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-dairy/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=dairy&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: '7BnCXXpA8E8~fbb',
				name: 'Pasta', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/pasta/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvx77',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-pasta/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=pasta&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'GUKzwgCmEK0~f47',
				name: 'Bakery', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/bakery/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvx25',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-bakery/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=bakery&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'cQsQBfOYqSw~ada',
				name: 'Conserve', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/conserve/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-conserve/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=conserve&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'PlwcDJy0Iyo~9b1',
				name: 'Condimenti', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/condimenti-mercati/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-condimenti/'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=condimenti-mercati&order='
					}
				]
			},
			{
				id: '.SEvp.SjiGE~e67',
				name: 'Olio', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/olio/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dpvg',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-olio/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=olio&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'dYUxdaImPIc~b46',
				name: 'Beverage', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/beverage/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-beverage/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=beverage&order='
					}
				]
			},
			{
				id: '8rPCaW78pAU~eed',
				name: 'Ortofrutta', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/ortofrutta/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-ortofrutta/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=ortofrutta&order='
					}
				]
			},
			{
				id: 's1wJbtxcKa0~d0a',
				name: 'Frozen', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/frozen/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-frozen/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=frozen&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'rMD0VOHhqM4~e7e',
				name: 'Fresco', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/fresco/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=fresco&order='
					}
				]
			},
			{
				id: 'hXygh.UD_yQ~de2',
				name: 'Grocery', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/grocery/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-grocery/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=grocery&order='
					}
				]
			},
			{
				id: 'vQUlCbGPQxc~9d6',
				name: 'retail', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2022-01-14/8q699y',
				news_link: 'https://www.foodweb.it/category/retail/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-retail/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Mappe e Whitepaper',
						slug: 'mappe-e-whitepaper',
						id: 'wYHrBsW297k~bd6',
						type: 'collection',
						name: 'Retail', // serve per matchare le category dei magazines; deve essere uguale a HUB!
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			}
		],
		KNOWLEDGECENTER_MAGAZINECOLLECTIONS: [
			{
				name: 'rivista', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'nfRj91B7qmM~cf4',
				color: '#89a6c0',
				label: 'Mensile',
				in_dashboard: true
			},
			{
				name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: '4aSIksnqH08~f0d',
				in_dashboard: false
			},
			{
				name: 'annual reports', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: '53AfVrMcCBk~b57',
				color: '#ec6f68',
				in_dashboard: true
			}			
		],
		KNOWLEDGECENTER_SECTIONS: [
		]
	},
	'foodserviceweb.foodweb.it': {
		TARGET_SITE: 'FOODSERVICEWEB',
		BASE_URL: prodAPIUrl,
		VERIFICATION_URL: globalVerificationUrl,
		GRAPHQL_API: prodAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: prodAPIUrl + '/api/authenticate',
			VERIFY: prodAPIUrl + '/api/verify',
			TRACKING: prodAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: prodAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: prodAPIUrl + '/api/confirm-password'
		},
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'FOOD_SERVICE',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM:
			'https://pardot.foodserviceweb.it/l/656763/2020-01-31/n71bh',
		SF_ITEM: 'FOOD_SERVICE_DIGITALE_PERIODICO_COMPLETO',
		HIDE_HOME_LAST_ITEM: true,
		LOGO: logoFoodService,
		RIVISTA: Abbonamento__C_Rivista__C_Enum.FoodService,
		STRIPE_PUBLIC_KEY: prodStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Foodservice,
		SUBSCRIBE_PATH: '/abbonati-a-foodservice/'
	},

	'news-italianfood.foodweb.it': {
		TARGET_SITE: 'IFN',
		BASE_URL: prodAPIUrl,
		VERIFICATION_URL: globalVerificationUrl,
		GRAPHQL_API: prodAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: prodAPIUrl + '/api/authenticate',
			VERIFY: prodAPIUrl + '/api/verify',
			TRACKING: prodAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: prodAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: prodAPIUrl + '/api/confirm-password'
		},
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'ITALIANFOOD_NET',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		LANG: 'en',
		SUPPORT_EMAIL: 'support@italianfood.net',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.italianfood.net/l/656763/2020-02-04/p1cgt',
		LOGO: logoItalianFoodNet,
		STRIPE_PUBLIC_KEY: prodStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.ItalianfoodNet,
		KNOWLEDGECENTER_VERTICALS: [
			{
				id: 'rZNGT8f29Uw~bbe',
				name: 'Salumi', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/salumi/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh5m',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-salumi/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=salumi&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'qH5cqsIPTO0~fc6',
				name: 'Carni', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/carni/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-carni/'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=carni&order='
					}
				]
			},
			{
				id: 'S9h8mhaGXbw~98b',
				name: 'Dairy', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/dairy/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh6x',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-dairy/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=dairy&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: '7BnCXXpA8E8~fbb',
				name: 'Pasta', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/pasta/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvx77',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-pasta/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=pasta&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'GUKzwgCmEK0~f47',
				name: 'Bakery', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/bakery/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvx25',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-bakery/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=bakery&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'cQsQBfOYqSw~ada',
				name: 'Conserve', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/conserve/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-conserve/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=conserve&order='
					}
				]
			},
			{
				id: 'PlwcDJy0Iyo~9b1',
				name: 'Condimenti', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/condimenti-mercati/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-condimenti/'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=condimenti-mercati&order='
					}
				]
			},
			{
				id: '.SEvp.SjiGE~e67',
				name: 'Olio', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/olio/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2021-01-18/4vvydp',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-olio/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=olio&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			},
			{
				id: 'dYUxdaImPIc~b46',
				name: 'Beverage', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/beverage/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-beverage/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=beverage&order='
					}
				]
			},
			{
				id: '8rPCaW78pAU~eed',
				name: 'Ortofrutta', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/ortofrutta/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-ortofrutta/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=ortofrutta&order='
					}
				]
			},
			{
				id: 's1wJbtxcKa0~d0a',
				name: 'Frozen', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/frozen/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-frozen/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=frozen&order='
					}
				]
			},
			{
				id: 'rMD0VOHhqM4~e7e',
				name: 'Fresco', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/fresco/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=fresco&order='
					}
				]
			},
			{
				id: 'hXygh.UD_yQ~de2',
				name: 'Grocery', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/grocery/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-grocery/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=grocery&order='
					}
				]
			},
			{
				id: 'vQUlCbGPQxc~9d6',
				name: 'retail', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2022-01-14/8q699y',
				news_link: 'https://www.foodweb.it/category/retail/',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '4aSIksnqH08~f0d'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-retail/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			}
		],
		KNOWLEDGECENTER_MAGAZINECOLLECTIONS: [
			{
				name: 'magazine', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'tw1vwVBUlmg~8e4',
				color: '#89a6c0',
				label: 'Magazine',
				in_dashboard: true
			},
			{
				name: 'special issues', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: '5KqEuNxtXW8~f2b',
				in_dashboard: false
			},
			{
				name: 'reports', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'nKMgzxwGO0g~b86',
				color: '#ec6f68',
				in_dashboard: true
			}			
		],
		KNOWLEDGECENTER_SECTIONS: [
		]
	},
	'dev-foodweb.foodweb.it': {
		TARGET_SITE: 'FOODWEB',
		BASE_URL: devAPIUrl,
		GRAPHQL_API: devAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: devAPIUrl + '/api/authenticate',
			VERIFY: devAPIUrl + '/api/verify',
			TRACKING: devAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: devAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: devAPIUrl + '/api/confirm-password'
		},
		VERIFICATION_URL: globalVerificationUrl,
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'FOOD',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		SUBSCRIBE_PATH: '/abbonati-a-food/',
		AWARD_FILTER_NAME: 'Prodotto FOOD',
		AWARD_VOTE_URL: 'https://vote.prodottofood.it',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.gruppofood.com/l/656763/2020-02-04/p1cgk',
		SF_ITEM: 'FOOD_DIGITALE_PERIODICO_COMPLETO',
		HIDE_HOME_LAST_ITEM: false,
		LOGO: logoFood,
		STRIPE_PUBLIC_KEY: devStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/edicola/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Food,
		KNOWLEDGECENTER_VERTICALS: [
			{
				id: 'NA35BpN2_7U~fdf',
				name: 'Salumi', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				news_link: 'https://www.foodweb.it/category/mercati/salumi/',
				newsletter_link: 'https://pardot.gruppofood.com/l/656763/2023-08-23/f3dh5m',
				tabs: [
					{
						title: 'I Mercati',
						slug: 'mercati',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: '53AfVrMcCBk~b57'
					},
					{
						title: 'Data',
						slug: 'data',
						type: 'wordpressData',
						url: 'https://www.foodweb.it/knowledge-center-data-salumi/'
					},
					{
						title: 'Annual Reports',
						slug: 'annual-reports',
						type: 'collection',
						name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
						id: 'JT6qGoXw3Lw~b57'
					},
					{
						title: 'Lanci di prodotto',
						type: 'extlink',
						target: '_blank',
						url: 'https://www.foodweb.it/launch/?product_category=salumi&order='
					},
					{
						title: 'Newsletter',
						slug: 'newsletter',
						type: 'newsletter'
					}
				]
			}
		],
		KNOWLEDGECENTER_MAGAZINECOLLECTIONS: [
			{
				name: 'rivista', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'nfRj91B7qmM~cf4',
				color: '#89a6c0',
				label: 'Mensile',
				in_dashboard: true
			},
			{
				name: 'mercati', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: '53AfVrMcCBk~b57',
				in_dashboard: false
			},
			{
				name: 'annual reports', // serve per matchare le category dei magazines; deve essere uguale a HUB!
				id: 'JT6qGoXw3Lw~b57',
				color: '#ec6f68',
				in_dashboard: true
			}			
		],
		KNOWLEDGECENTER_SECTIONS: [
		]
	},
	'dolcesalato.foodweb.it': {
		TARGET_SITE: 'DOLCESALATO',
		BASE_URL: prodAPIUrl,
		GRAPHQL_API: prodAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: prodAPIUrl + '/api/authenticate',
			VERIFY: prodAPIUrl + '/api/verify',
			TRACKING: prodAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: prodAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: prodAPIUrl + '/api/confirm-password'
		},
		VERIFICATION_URL: globalVerificationUrl,
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		BRAND_SF: 'DOLCESALATO',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		AWARD_FILTER_NAME: 'Prodotto DOLCESALATO',
		AWARD_VOTE_URL: 'https://awards.dolcesalato.com',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.dolcesalato.com/l/656763/2020-02-04/p1chf',
		SF_ITEM: 'DOLCESALATO_DIGITALE_PERIODICO_COMPLETO',
		HIDE_HOME_LAST_ITEM: false,
		LOGO: logoDolcesalato,
		RIVISTA: Abbonamento__C_Rivista__C_Enum.Dolcesalato,
		STRIPE_PUBLIC_KEY: prodStripeApi,
		ECOMMERCE_IBAN,
		EDICOLA_HOME_URL: '/edicola/',
		ECOMMERCE_HELP_URL: '/abbonati-a-food/#supporto',
		ECOMMERCE_BRAND: Product2_Brand__C_EnumFiltered.Dolcesalato,
		SUBSCRIBE_PATH: '/abbonati-a-dolcesalato/'
	},
	'stage.personalcare360.it': {
		TARGET_SITE: 'FOODWEB',
		BASE_URL: devAPIUrl,
		GRAPHQL_API: devAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: devAPIUrl + '/api/authenticate',
			VERIFY: devAPIUrl + '/api/verify',
			TRACKING: devAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: devAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: devAPIUrl + '/api/confirm-password'
		},
		VERIFICATION_URL: globalVerificationUrl,
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.foodweb.it/l/656763/2025-01-07/f4xp2q',
		BRAND_SF: 'PERSONALCARE360',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		LOGO: logoPersonalCare360,
		SUBSCRIBE_PATH: '/'
	},
	'www.personalcare360.it': {
		TARGET_SITE: 'FOODWEB',
		BASE_URL: prodAPIUrl,
		GRAPHQL_API: prodAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: prodAPIUrl + '/api/authenticate',
			VERIFY: prodAPIUrl + '/api/verify',
			TRACKING: prodAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: prodAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: prodAPIUrl + '/api/confirm-password'
		},
		VERIFICATION_URL: globalVerificationUrl,
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.foodweb.it/l/656763/2025-01-07/f4xp2q',
		BRAND_SF: 'PERSONALCARE360',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		LOGO: logoPersonalCare360,
		SUBSCRIBE_PATH: '/'
	},
	'stage.homecare360.it': {
		TARGET_SITE: 'FOODWEB',
		BASE_URL: devAPIUrl,
		GRAPHQL_API: devAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: devAPIUrl + '/api/authenticate',
			VERIFY: devAPIUrl + '/api/verify',
			TRACKING: devAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: devAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: devAPIUrl + '/api/confirm-password'
		},
		VERIFICATION_URL: globalVerificationUrl,
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.foodweb.it/l/656763/2025-01-07/f4xnwb',
		BRAND_SF: 'PERSONALCARE360',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		LOGO: logoHomeCare360,
		SUBSCRIBE_PATH: '/'
	},
	'www.homecare360.it': {
		TARGET_SITE: 'FOODWEB',
		BASE_URL: prodAPIUrl,
		GRAPHQL_API: prodAPIUrl + '/graphql',
		API_ENDPOINT: {
			AUTH: prodAPIUrl + '/api/authenticate',
			VERIFY: prodAPIUrl + '/api/verify',
			TRACKING: prodAPIUrl + '/api/bucket/tracking',
			FORGOT_PASSWORD: prodAPIUrl + '/api/forgot-password',
			RESET_PASSWORD: prodAPIUrl + '/api/confirm-password'
		},
		VERIFICATION_URL: globalVerificationUrl,
		SENTRY_SERVER: 'https://ad2580de084c4eff84ad34e2e21c6815@sentry.io/1763840',
		PARDOT_NEWSLETTER_FORM: 'https://pardot.foodweb.it/l/656763/2025-01-07/f4xnwb',
		BRAND_SF: 'PERSONALCARE360',
		OTHER_BRAND_SF: [],
		theme: deployTheme,
		FLUENT_LOAD_PATH:
			'/wp-content/uploads/react/user-dashboard/locales/{{lng}}/{{ns}}.ftl',
		LANG: 'it',
		SUPPORT_EMAIL: 'support@gruppofood.com',
		LOGO: logoHomeCare360,
		SUBSCRIBE_PATH: '/'
	},
};
console.log("--",configs);
export const config = configs[window.location.hostname];

export const authConfigDefault = {
	AUTH_ROLES: [UserRole.Consumer],
	TARGET_SITE: config.TARGET_SITE,
	AUTH_ENDPOINT: config.API_ENDPOINT.AUTH,
	LOGO: config.LOGO,
	VERIFICATION_URL: config.VERIFICATION_URL,
};
